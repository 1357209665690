import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { AppState } from "@/context/AppContext";
import AuthLayout from "@/layouts/AuthLayout";
import AutoForm from "@/components/ui/auto-form";
import * as z from "zod";
import { User } from "@/models";
import { track } from "@/lib/analytics";
import { useTitle } from "@/lib/title";

const formSchema = z.object({
  name: z.string().min(2).describe("Company Name"),
  website: z.string().describe("Website"),
  company_size: z.enum(["1-9", "10-49", "50-99", "100-249", "250-499", "500+"]),
});

type OnboardingValues = z.infer<typeof formSchema>;

export default function NewCustomer({ user }: { user: User }) {
  useTitle("Create Customer Account");

  const queryClient = useQueryClient();

  const createCustomerRequest = useMutation((values: OnboardingValues) => {
    return axios.post<AppState>("/customers", values).then(({ data }) => {
      track("Created Customer", values);
      queryClient.setQueryData(["me"], data);
    });
  });

  return (
    <AuthLayout
      title={`Welcome, ${user.name}`}
      description=" Let's set up an account for your company."
    >
      <AutoForm
        formSchema={formSchema}
        onSubmit={createCustomerRequest.mutateAsync}
        fieldConfig={{
          name: {
            inputProps: {
              placeholder: "Acme Inc.",
            },
          },
          website: {
            inputProps: {
              placeholder: "acme.com",
              autoCapitalize: "off",
            },
            description: "Your company's domain",
          },
        }}
        defaultValues={{}}
        submitLabel="Create Account"
      />
    </AuthLayout>
  );
}
