import { useGoogleLogin } from "@react-oauth/google";
import axios, { AxiosError } from "axios";
import { Button } from "@/components/ui/button";
import microsoft from "@/assets/microsoft.svg";
import google from "@/assets/google.svg";
import { useMsal } from "@azure/msal-react";
import { useAfterLogin } from "@/lib/auth";
import { track } from "@/lib/analytics";

export default function SocialLogins({
  verb,
  onError,
}: {
  verb: string;
  onError: (error: string) => void;
}) {
  const afterLogin = useAfterLogin();
  const { instance } = useMsal();

  const getShouldRememberMe = (): boolean => {
    const rememberMeCheckbox = document.getElementById(
      "remember-me-checkbox",
    ) as HTMLInputElement | undefined;
    return rememberMeCheckbox?.checked || false;
  };

  const login = useGoogleLogin({
    scope:
      "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    onSuccess: (tokenResponse) => {
      axios
        .post(`/oauth/google`, {
          ...tokenResponse,
          remember: getShouldRememberMe(),
        })
        .then(() => {
          track("Logged In", {
            provider: "Google",
          });
          return afterLogin();
        })
        .catch((error) => {
          if (error instanceof AxiosError) {
            onError(error.response?.data.message || "An error occurred");
          }
        });
    },
  });

  return (
    <div className="space-y-2">
      <Button
        variant="outline"
        className="w-full justify-between rounded-lg"
        onClick={() => login()}
      >
        <img src={google} className="w-6" />
        <span>{verb} with Google</span>
        <div />
      </Button>
      <Button
        variant="outline"
        className="w-full justify-between rounded-lg"
        onClick={() => {
          instance
            .loginPopup({
              redirectUri: window.location.href.split("?")[0],
              scopes: ["User.Read", "openid", "profile", "email"],
            })
            .then((response) => {
              axios
                .post("/oauth/microsoft", {
                  access_token: response.accessToken,
                  remember: getShouldRememberMe(),
                })
                .then(() => {
                  track("Logged In", {
                    provider: "Microsoft",
                  });
                  return afterLogin();
                });
            });
        }}
      >
        <img src={microsoft} className="w-6" />
        <span>{verb} with Microsoft</span>
        <div />
      </Button>
    </div>
  );
}
